import React, { FC } from 'react';

export interface ISkipNav {
    navigationID: string;
    mainContentID: string;
}

export const SkipNav: FC<ISkipNav> = (props: ISkipNav) => {
    const {navigationID, mainContentID} = props;

    return (
        <ul className="skip" role="navigation">
            <li><a href={`#${navigationID}`}>To navigation</a></li>
            <li><a accessKey="s" href={`#${mainContentID}`}>To content</a></li>
        </ul>
    )
}

export default SkipNav;