import React, {FC} from 'react';
import RouteTitle from "../components/RouteTitle";
import '../styling/About.scss';

export const About: FC = () => {
  return (
    <main className="site__main">
      <div className={`with-sections wrapper about-page`}>
        <section id="about-the-site">
          <RouteTitle title="About the website">
            <p>
              { /*  eslint-disable-next-line max-len */ }
              This website is powered by The Swedish Internet Foundation. The website is used in regards of verifying the identity of a registrant under the top-level domain for .se or .nu. The ID-verification is done in cooperation with the provider Signicat, https://www.signicat.com/
            </p>
            <p>
              { /*  eslint-disable-next-line max-len,react/no-unescaped-entities */ }
              The Swedish Internet Foundation is an independent, business-driven and public-benefit organization. We work for an internet that contributes positively to people and society. We are responsible for the internet's Swedish top-level domain .se and manages the operation and administration of the top-level domain .nu. The revenue from our business finances a number of initiatives in order to enable people to use the internet in the best way, and to stimulate the sharing of knowledge and innovation with a focus on the internet.
            </p>
          </RouteTitle>
        </section>

        <section>
          <div className="m-info-box" id="cookies">
            <h2>About cookies</h2>

            <p>
              { /*  eslint-disable-next-line max-len */ }
              A cookie is a short text file that the website you are visiting asks to be saved on your computer for a certain period of time. Many websites use cookies to give you as a visitor access to various features that make it smoother and easier to use the site.
            </p>
            <p>
              { /*  eslint-disable-next-line max-len */ }
              There are different types of cookies; those that only remain on the device during your visit to the website and those that remain for a predetermined time. A session cookie is temporarily stored on your device while you are on the website, but is removed as soon as you close your browser. A persistent cookie remains on your device for a predetermined period of time. In the list below you will find how long time the different cookies are stored.
            </p>
            <p>
              { /*  eslint-disable-next-line max-len */ }
              Types of cookies used being used on this website:
              <br />
              <strong>This website does not use cookies.</strong>
            </p>
          </div>
        </section>
      </div>
    </main>
    )
};

export default About;
