import React, { FC, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

/** Presentation */
import Landing from './Landing';
import TopNav from "../components/Navigation/TopNav";
import Footer from "../components/Navigation/Footer"
import SkipNav from '../components/Navigation/SkipNav';
import Spinner from '../components/Spinner';
import ErrorBoundary from '../components/ErrorBoundary';
import About from './About';
import LandingWithParameters from './LandingWithParameters';

const RootContainer: FC = () => {
  const mainContentID = "siteMain"
  const navigationID = "siteNav"

  useEffect(() => {
    document.body.addEventListener('keydown', (event) => {
      const keyCode = event.key;
      if (keyCode === 'Tab' ) {
        document.body.classList.add('onFocus');
      }
    });

    document.body.addEventListener('mousedown', () => {
      document.body.classList.remove('onFocus');
    });
  }, []);

  return (
    <div id="site" className="site">
      <SkipNav mainContentID={mainContentID} navigationID={navigationID}/>
      <TopNav />
      <div id={mainContentID} className="site__main">
        <div className="site__main__content">
        <Router>
          <ErrorBoundary>
            <Suspense fallback={<Spinner className="pade-load-spinner" />}>
              <Routes>
                <Route path="/om-webbplatsen" element={<About />} />
                <Route path="/" element={<Landing />} />
                <Route path="/:signedDossierID" element={<LandingWithParameters loc={window.location} />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </Router>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RootContainer;
