import React, {FC} from 'react';

interface ISectionProps {
	children: React.ReactNode;
	className?: string;
}

const Section: FC<ISectionProps> = (props: ISectionProps) => {
	const { children, className } = props;
	const sectionClass = className ? className : '';
	return (
		<section className={`o-section ${sectionClass}`}>
			<div className="wrapper o-section__content">
				{children}
			</div>
		</section>
	)
};

export default Section;