import React, {FC} from 'react';
import Section from '../components/Section';

const Landing: FC = () => {

	return (
		<main role="main" className="u-p-b-2">
			<Section className="u-p-y-0">
				<div className="article">
					<h1 className="supersize u-m-t-4">Registry ID verification</h1>
					<div className="article__content article__content--full-width">
						{/* eslint-disable max-len */}
						<p className="preamble width-full">
							This website is powered by The Swedish Internet Foundation. The website is used in regards of verifying the identity of a registrant under the top-level domain for .se or .nu. The ID-verification is done in cooperation with the provider Signicat, https://www.signicat.com/
						</p>
						{/* eslint-enable max-len */}
					</div>
				</div>
			</Section>

		</main>
	);
};

export default Landing;
