import { getApiURL } from './Environment';
import {
  VerificationLinkRequest,
  VerificationLinkResponse,
  VerificationStatusResponse,
  ErrorResponse,
  VerificationStatusRequest,
  SyncStatusResponse,
  ProcessStatus
} from '../custom-types/Request';
import {Err, Ok, Result} from "./Result";

export const parseJsonError = (res: Response): Promise<ErrorResponse> => {
  const errObj: ErrorResponse = {errors: [res.statusText]};

  return res.json().then((error) => {
    if (error && {}.propertyIsEnumerable.call(error, 'errors')) {
      errObj.errors = error.errors;
    }

    if (error && {}.propertyIsEnumerable.call(error, 'fields')) {
      errObj.fields = error.fields;
    }

    return Promise.reject(errObj);
  }).catch(() => {
    // Catch empty body / cannot be json parsed
    return Promise.reject(errObj);
  })
};

/**
 * API Request handler
 * @param url        - api endpoint
 * @param method     - http method
 * @param bodyParams - body parameters of request
 */
const apiRequest = async (
  url: string,
  method: string,
  bodyParams?: object
  // eslint-disable-next-line
): Promise<any> => {
  const myHeaders = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  const apiURL = getApiURL();
  const fullUrl = apiURL && !(url.startsWith('.') || url.startsWith('http')) ? `${apiURL}${url}` : url;

  const req = fetch(
    fullUrl,
    {
      method,
      headers: myHeaders,
      body: bodyParams ? JSON.stringify(bodyParams) : undefined,
    },
  );

  return req.then((res) => {
    if (!res.ok) {
      return parseJsonError(res);
    }

    return res.json();
  })
  .then((resData) => new Ok(resData))
  .catch((reason) => new Err(reason))
};

// eslint-disable-next-line max-len
export const getVerificationLink = (params: VerificationLinkRequest): Promise<Result<VerificationLinkResponse, ErrorResponse>> => {
  return apiRequest(`/v2/process/url?signed_dossier_id=${params.signed_dossier_id}`, "GET");
};

// eslint-disable-next-line max-len
export const notifyVerificationCompleted = (params: VerificationStatusRequest): Promise<Result<VerificationStatusResponse, ErrorResponse>> => {
  return apiRequest(`/v2/process/upload_done`, "POST", params);
};

/**
 * Will initiate request and retry after a delay if status indicates pending.
 * This is because according to API 95% of verification
 * processes have then been processed and synced.
 * @param params a VerificationStatusRequest object type
 * @param retries a number of retries to attempt
 * @param delay a number indicating how long between retries
 * @returns a resolving promise: Promise<Result<SyncStatusResponse, ErrorResponse>>
 */
// eslint-disable-next-line max-len
export const getSyncedProcessStatus = (params: VerificationStatusRequest, retries: number, delay = 10000): Promise<Result<SyncStatusResponse, ErrorResponse>> => {
  const req: Promise<Result<SyncStatusResponse, ErrorResponse>> = 
  apiRequest(`/v2/process/sync?signed_process_id=${params.signed_process_id}`, "GET");
  
  return req.then((resp) => {
    const retriesRemaining = retries - 1;

    if (!resp.isOk()) {
      return resp;
    }

    switch (resp.value.process_status) {
      case ProcessStatus.NotStarted:
      case ProcessStatus.Pending:
      case ProcessStatus.Processing:
        // Retry fetching statuses in case sync is slow.
        break;
      default:
        // Other statuses does not need to be retried.
        return resp
    }

    if (retriesRemaining <= 0) {
      return resp
    }
    
    return wait(delay).then(() => getSyncedProcessStatus(params, retriesRemaining, delay))
  })
};

const wait = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
}