import React, {FC, useEffect, useState} from 'react';
import Card from '../components/Card';
import {ReactComponent as LoadingIcon} from "../images/icon-spinner.svg";
import { getSyncedProcessStatus, getVerificationLink, notifyVerificationCompleted } from '../utils/Requests';
import Section from '../components/Section';
import { useParams } from 'react-router-dom';
import { ProcessStatus, VerificationStatusRequest } from '../custom-types/Request';

interface ILandingProps {
	loc: Location;
}

const LandingWithParameters: FC<ILandingProps> = (props: ILandingProps) => {
	const { loc } = props;
	const { signedDossierID } = useParams();

	// These 4 below correlate with the external link.
	const [hasSignedDossierID, setHasSignedDossierID] = useState(false);
	const [loadingExternalLink, setLoadingExternalLink] = useState(false);
	const [externalLink, setExternalLink] = useState('');
	const [externalLinkError, setExternalLinkError] = useState('');

	// These 5 below correlate with the upload done.
	const [loadingVerifyPolling, setLoadingVerifyPolling] = useState(false);
	const [verificationParam, setVerificationParam] = useState('');
	const [verificationStatus, setVerificationStatus] = useState('');
	const [verificationError, setVerificationError] = useState('');
	const [verificationInfo, setVerificationInfo] = useState('');

	// On load check path and searchparams to determine if
	// should initiate ID validation process OR
	// status verification polling.
	useEffect(() => {
		if (!signedDossierID) {
			return;
		}

		// Then check length of url parameter if it resembles
		// length of auth hash.
		if (signedDossierID.length >= 64 ) {
			const searchParams = new URLSearchParams(loc.search);
			const signicatProcessParam = searchParams.get('processId')

			if (!signicatProcessParam) {
				// Check that mailparam is correct format.
				setHasSignedDossierID(true)
				fetchExternalLink(signedDossierID)
				setLoadingExternalLink(true)
				return;
			}

			// Start polling for verification status.
			verificationComplete(signedDossierID, signicatProcessParam)
		}
	}, [signedDossierID])

	/**
	 * Attempt to retrieve external link to Signicat. If succesful display 
	 * the link to the 3rd party provider which will handle the actual
	 * verification process.
	 * The API call will also return a status which can mean the verification
	 * is already completed. In that case show a success message instead.
	 * @param signedDossierID ID for the verification process
	 * @returns void
	 */
	const fetchExternalLink = (signedDossierID: string) => {
		if (loadingExternalLink) {
			return
		}

		setLoadingExternalLink(true);
		setExternalLinkError('');
		setExternalLink('')

		getVerificationLink({signed_dossier_id: signedDossierID}).then((resp) => {
			resp.match({
				Ok: (okData) => {
					const showSignicatLinkStatuses = [ProcessStatus.NotStarted, ProcessStatus.Pending]
					
					if (okData.value.url && showSignicatLinkStatuses.includes(okData.value.status)) {
						setExternalLink(okData.value.url)
						return;
					}

					// The case below _shouldn't_ happen. We should only ever receive ongoing processes.
					// eslint-disable-next-line max-len
					setVerificationInfo(`No new process to start (status:${okData.value.status}). Please check your mail inbox.`)
					
				},
				Err: (errData) => {
					const errstring = errData.err.errors.toString();
					if (errstring.includes('max verification attempts reached')) {
						setExternalLinkError('Limit reached, too many retries. Please contact Registry support.')
						return;
					}

					setExternalLinkError(errstring)
				}
			})
		}).catch((e) => {
			console.error("Error fetching external link:", e)
			// eslint-disable-next-line max-len
			setExternalLinkError('Unknown error. Please reload the page or contact support if the problem persists.');
		}).finally(() => {
			setLoadingExternalLink(false);
		})
	}

	const verificationComplete = (signedProcessID: string, signicatProcessID: string) => {
		if (loadingVerifyPolling || !signedProcessID || !signicatProcessID) {
			return
		}

		setLoadingVerifyPolling(true);
		setVerificationParam(signicatProcessID)
		setVerificationError('');
		setVerificationStatus('')
		setVerificationInfo('')

		const params: VerificationStatusRequest = {
			signed_process_id: signedProcessID,
			signicat_process_id: signicatProcessID
		}

		notifyVerificationCompleted(params)
			.then(() => getSyncedProcessStatus(params, 5))
			.then((resp) => {
				resp.match({
					Ok: (okData) => {
						displayVerificationStatus(okData.value.process_status)
					},
					Err: (errData) => {
						setVerificationError(errData.err.errors.toString())
					}
				})
			})
			.catch((e) => {
				console.error("Verification error:", e)
				// eslint-disable-next-line max-len
				setVerificationError('Could not confirm ID verification process has completed succesfully. If this problem persists please contact our support.');
			}).finally(() => {
				setLoadingVerifyPolling(false);
			})
	}

	const displayVerificationStatus = (status: ProcessStatus) => {
		/* eslint-disable max-len */
		switch (status) {
			case ProcessStatus.Accepted:
					setVerificationStatus("Your ID verification was succesful at this stage. The finalization of the verification will be made by Registry Services and you will be informed by email when this has been done.")
				break;
				case ProcessStatus.Canceled:
					setVerificationInfo("Operation cancelled")
				break;
				case ProcessStatus.Failed:
					setVerificationInfo("A technical error occurred")
				break;
				case ProcessStatus.Inconclusive:
				case ProcessStatus.Rejected:
					setVerificationError("Your ID-verification was unsuccessful and we were not able to verify your identity. Please contact your registrar for further assistance.")
				break;
				case ProcessStatus.NotStarted:
				case ProcessStatus.Pending:
				case ProcessStatus.Processing:
					setVerificationInfo("Verification status is 'pending'. Please wait for one minute and then refresh this webpage. If you don't get a final result after that please contact our support.")
				break;
			default:
				setVerificationError("Unknown error occured. Please try again.")
				console.error("Unknown verification status:", status)
				break;
		}
		/* eslint-enable max-len */
	}

	return (
		<main role="main" className="u-p-b-2">
			<Section className="u-p-y-0">
				<div className="article">
					<h1 className="supersize u-m-t-4">Registry ID verification</h1>
					<div className="article__content article__content--full-width">
						{/* eslint-disable max-len */}
						<p className="preamble width-full">
							This website is powered by The Swedish Internet Foundation. The website is used in regards of verifying the identity of a registrant under the top-level domain for .se or .nu. The ID-verification is done in cooperation with the provider Signicat, https://www.signicat.com/.
							<br />
							This site will initiate your verification process.
						</p>
						{/* eslint-enable max-len */}
					</div>
				</div>
			</Section>

			<Section className="u-p-t-3">
				<div className="row justify-content-center">
					<div className="grid-12">
						{hasSignedDossierID && loadingExternalLink && (
							<Card>
								<p>Please wait for the verification process to start and follow the instructions...</p>
								{loadingExternalLink && (
									<div className="spinner-wrapper">
										<LoadingIcon className="icon a-button__spinner a-button__icon" />
									</div>
								)}
							</Card>
						)}

						{!hasSignedDossierID && verificationParam === '' && (
							<div role="alert" className="m-alert m-alert--error u-m-b-0">
								<h2 className="u-m-b-1">An error occurred</h2>
								<p>Invalid link please check you email again.</p>
							</div>
						)}

						{hasSignedDossierID && !loadingExternalLink && (
							<Card>
								{externalLinkError !== '' && (
									<div role="alert" className="m-alert m-alert--error u-m-b-0">
										<h2 className="u-m-b-1">An error occurred</h2>
										<p>Could not fetch verification process status:</p>
										<p>{externalLinkError}</p>
									</div>
								)}

								{externalLink !== '' && (
									<div>
										{/* eslint-disable-next-line max-len */}
										<p>Click the link below to go to the third party ID verification partner and follow the instructions.</p>
										<a id="verification-link" className="u-link" href={externalLink} >
											Start verification process
										</a>
									</div>
								)}

								{verificationStatus !== '' && (
									<div role="alert" className="m-alert m-alert--success u-m-b-0">
										<h2 className="u-m-b-1">Success</h2>
										<p>{verificationStatus}</p>
									</div>
								)}
							</Card>
						)}

						{verificationParam && (
							<Card>
								{loadingVerifyPolling && (
									<div>
										<p>Checking verification status. This may take a few seconds.</p>
										<div className="spinner-wrapper">
											<LoadingIcon className="icon a-button__spinner a-button__icon" />
										</div>
									</div>
								)}

								{!loadingVerifyPolling && verificationError !== '' && (
									<div role="alert" className="m-alert m-alert--error u-m-b-0">
										<h2 className="u-m-b-1">An error occurred.</h2>
										<p>{verificationError}</p>
									</div>
								)}

								{!loadingVerifyPolling && verificationStatus !== '' && verificationError === '' && (
									<div role="alert" className="m-alert m-alert--success u-m-b-0">
										<h2 className="u-m-b-1">Success</h2>
										<p>{verificationStatus}</p>
									</div>
								)}

								{!loadingVerifyPolling && verificationInfo !== '' && verificationError === '' && (
									<div role="alert" className="m-alert m-alert--info u-m-b-0">
										<h2 className="u-m-b-1">Status</h2>
										<p>{verificationInfo}</p>
									</div>
								)}
							</Card>
						)}
					</div>
				</div>
			</Section>
		</main>
	);
};

export default LandingWithParameters;
