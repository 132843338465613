/*
* This file provides types that are used as either request payloads or responses.
*/

export type ErrorResponse = {
    errors: string[];
    fields?: Record<string, unknown>;
}

export type VerificationLinkRequest = {
  signed_dossier_id: string;
}

export type VerificationStatusRequest = {
  signed_process_id: string;
  signicat_process_id: string;
}

export type VerificationLinkResponse = {
  url: string;
  status: ProcessStatus;
}

export type VerificationStatusResponse = {
  status: ProcessStatus;
}

export type SyncStatusResponse = {
  process_status: ProcessStatus;
}

export enum ProcessStatus {
  NotStarted = "notStarted",
  Pending = "pending",
  Processing = "processing",
  Failed = "failed",
  Canceled = "canceled",
  Accepted = "accepted",
  Inconclusive = "inconclusive",
  Rejected = "rejected"
}
