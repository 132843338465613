import React, {FC} from 'react';
import '../styling/RouteTitle.scss';

interface IRouteTitleProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  extendMaxWidth?: boolean;
}

const RouteTitle: FC<IRouteTitleProps> = (props: IRouteTitleProps) => {
  const { title, description, children, extendMaxWidth } = props;
  const maxWidthClass = extendMaxWidth ? 'max-width-extended' : '';

  return (
    <div className={`route-title ${maxWidthClass}`}>
      <h1 className="supersize">
        {title}
      </h1>
      <div className="description">
        {description && (
          <p>
            {description}
          </p>
        )}

        {children}
      </div>
    </div>
  );
};

export default RouteTitle;
